// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-info-pages-markdown-jsx": () => import("/opt/build/repo/src/templates/info-pages-markdown.jsx" /* webpackChunkName: "component---src-templates-info-pages-markdown-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-donate-jsx": () => import("/opt/build/repo/src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thanks-contact-jsx": () => import("/opt/build/repo/src/pages/thanks-contact.jsx" /* webpackChunkName: "component---src-pages-thanks-contact-jsx" */),
  "component---src-pages-thanks-volunteer-jsx": () => import("/opt/build/repo/src/pages/thanks-volunteer.jsx" /* webpackChunkName: "component---src-pages-thanks-volunteer-jsx" */),
  "component---src-pages-volunteer-sign-up-jsx": () => import("/opt/build/repo/src/pages/volunteer/sign-up.jsx" /* webpackChunkName: "component---src-pages-volunteer-sign-up-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

